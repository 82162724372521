<template>
  <main class="min-h-screen">
    <h2 class="p-10 text-center text-5xl">SIVINUR</h2>
    <a-result
      status="404"
      title="En construcción"
      sub-title="Lo sentimos, no encontramos la página que buscas."
    >
      <template #extra>
        <a-button @click="goBack" type="primary">
          Regresar
          <!-- <router-link to="/">Ir a inicio</router-link> -->
        </a-button>
      </template>
    </a-result>
  </main>
</template>
<script>
import router from "@/router";

export default {
  setup() {
    // ------- onBack -------
    const goBack = () => router.back();

    return {
      goBack
    };
  }
};
</script>
